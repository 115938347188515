.footer2 {
    background: #2c2c2c;
}

.footer2 {
    padding-top: 35px;
    padding-bottom: 25px;
    font-size: 13.5px;
}

.usefull-links ul {
    display: flex;
    flex-direction: inherit;
    flex-wrap: wrap;
    justify-content: center;
}

.usefull-links ul li a {
    font-size: 13px;
    font-weight: 400;
    line-height: 32px;
    color: #fff;
    font-family: 'Playfair Display', sans-serif;
    text-transform: uppercase;
    letter-spacing: .2em;
    display: block;
    padding: 0.2em 1em;
}

.usefull-links.mb_25 {
    margin-bottom: 10px;
}

.get-itouch {
    text-align: center;
    margin-bottom: 15px;
}

.get-itouch ul li a {
    color: #a99575;
    display: inline-block;
    text-align: center;
    font-size: 13px;
    letter-spacing: 1px;
    /* text-transform: uppercase; */
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.main-footer2 .block-footer2 {
    margin-bottom: 15px;
}

.block-footer2 .social-network-footer a {
    border: 2px solid #a99575;
    background: rgba(255, 255, 255, 0.1);
    color: #a99575;
}

.block-footer2 .social-network-footer a {
    margin: 0 7px;
    line-height: 33px;
    margin-bottom: 10px;
}

.social-network-footer a {
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.round {
    border-radius: 50%;
}

.block-footer2 .social-network-footer a:hover {
    background: #a99575;
    color: #fff;
    border-color: #a99575;
}

.copyright-txt {
    text-align: center;
    padding: 0;
}

.copyright-txt p {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .5px;
}

.copyright-txt p a {
    color: #a99575;
}

.usefull-links ul li a:hover,
.usefull-links ul li a:focus {
    color: #a99575;
}

.badge-image-wrap img {
    max-width: 15%;
}

@media (min-width:320px) and (max-width:767px) {
    .copyright-txt {
        text-align: center;
        padding: 15px 0 0px;
    }
}