.k_customeHome_container{
    max-width: 1260px;
    width: 98%;
    margin: 0 auto;
    /* padding: 0; */
}
.k_wedding_bands_Section {
	padding: 30px 0;
}
.k_bands_img_text_box {
	position: relative;
}
.k_bands_text_box {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	padding: 30px;
}
.k_bands_img_text_box img {
	width: 100%;
	height: auto;
	transition: all 0.5s ease-in-out;
	min-height: 500px;
}
.k_bands_img_text_box{
    overflow: hidden;
    width: 100%;
}
.k_bands_img_text_box:hover img {
    transform: scale(1.1);
    transition: all 0.5s ease-in-out;
}
.k_bands_text_box h2 {
	font-size: 35px;
	line-height: 38px;
	margin-bottom: 20px;
	color: #fff;
	text-transform: capitalize;
	font-weight: 400;
}
.k_layout_heading h2 {
	font-size: 35px;
	line-height: 40px;
	text-transform: uppercase;
	color: #000;
	font-weight: 400;
	margin-bottom: 15px;
	text-align: center;
}
/* shop by shap style */
.k_shop_by_shap_img_text_box{
    padding: 15px;
    cursor: pointer;
}
.k_shop_by_shap_img_text_box img {
	width: 70px;
	height: 70px;
	object-fit: contain;
	margin: 0 auto 10px;
	text-align: center;
}
.k_shop_by_shap_img_text_box h6 {
	font-size: 18px;
	font-weight: 400;
	line-height: 23px;
	margin-bottom: 0;
	text-align: center;
	color: #000;
	text-decoration: underline;
	text-transform: capitalize;
	font-family: var(--font_1) !important;
}
.k_shop_by_shap_section {
	padding: 50px 0 60px;
}
.k_aniversary_eternity_section {
	padding: 40px 0;
}
.k_bands_img_text_box_outer {
	padding: 30px 7.5px 20px;
}
.k_aniversary_eternity_section .name {
    white-space: pre-wrap;
}
.k_shop_by_shap_img_text_box img {
	width: 65%;
	height: auto;
	object-fit: contain;
	margin: 0 auto 10px;
	text-align: center;
}
.k_ShopByCategory_section {
	padding: 50px 0 30px;
}
.k_ShopByCategory_section p {
	color: #555;
	font-size: 16px;
	text-align: center;
	margin: 20px auto 0;
}
/* Responstive style */
@media (min-width: 992px) {
    .k_customeHome_container{
        max-width: 1260px;
  width: 98%;
  margin: 0 auto;
  /* padding: 0; */
    }
}
@media(max-width:767px){
    .k_bands_img_text_box img {
        width: 100%;
        height: auto;
        transition: all 0.5s ease-in-out;
        min-height: auto;
    }
    .k_bands_img_text_box {
        position: relative;
        margin-bottom: 20px;
    }
    .k_bands_img_text_box::before {
        position: absolute !important;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: '';
        background-color: #0000007d;
    }
    .k_shop_by_shap_section {
        padding: 0px 0 0px;
    }
}
@media(min-width:768px) and (max-width: 991px){
    .k_bands_img_text_box img {
        width: 100%;
        height: auto;
        transition: all 0.5s ease-in-out;
        min-height: 350px;
        object-fit: cover;
    }
     }
@media(max-width:991px){
    .k_layout_heading h2 {
        font-size: 25px;
        line-height: 32px;
    }
    .k_bands_text_box h2 {
        font-size: 23px;
        line-height: 25px;
    }
}
@media(min-width:1150px) and (max-width:1440px){
    .k_customeHome_container {
        max-width: 1260px;
        width: 90%;
        margin: 0 auto;
    }
    .k_layout_heading h2 {
        font-size: 28px;
        line-height: 35px;
    }
    .k_bands_text_box h2 {
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .Lt_video_banner_content h2 {
        font-size: 30px !important;
        line-height: 35px !important;
        margin-bottom: 15px;
    }
    .london_banner_btn_outline.btn.btn-outline-dark {
        padding: 7px 20px;
        font-weight: 600;
        font-size: 11px;
        min-width: 120px;
    }
    .Lt_BestSeller a {
        font-size: 15px;
        line-height: 1.4;
    }
    .k_shop_by_shap_img_text_box h6 {
        font-size: 15px;
    }
    .london_banner_btn_fill.btn.btn-primary {
        padding: 7px 20px;
        font-size: 11px;
        min-width: 130px;
    }
    .k_aniversary_eternity_section {
        padding: 20px 0;
    }
    .k_ShopByCategory_section {
        padding: 30px 0 30px;
    }
    .Lt_NewsLetter_section {
        padding: 30px 0;
    }
    .Lt_NewsLetter_content h2 {
        margin-bottom: 15px;
        font-size: 25px;
        line-height: 35px;
    }
    .Lt_NewsLetter_content p {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
    }
    .k_shop_by_shap_section {
        padding: 30px 0 30px;
    }
    .Lt_bestSeller_section {
        padding: 30px 0px 30px 0px;
    }
}